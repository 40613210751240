import React from 'react';
import Helmet from 'react-helmet';
import LineFull from '../../images/linefull.jpg';

const YelpReviews = data => (
    <>
        <Helmet>
            <script
                async="async"
                src="https://www.yelp.com/embed/widgets.js"
                type="text/javascript"
            ></script>
        </Helmet>

        <section className="page-section">
            <div className="container yelpcontainer">
                <h2 className="bluetxt">Read our Yelp reviews</h2>

                <div className="yelp_reviews_list">
                    {data.reviewsList.map((review, index) => (
                        <div
                            className="yelpwrap"
                            key={`k_${index}`}
                            dangerouslySetInnerHTML={{
                                __html: review.yelp_review_code,
                            }}
                        />
                    ))}
                </div>

                <img className="margin-0-auto" src={LineFull} alt="line full" />
                <a
                    href="https://www.yelp.com/biz/we-rock-the-spectrum-kids-gym-tarzana-2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="wrtsbtn yellowbtn moreyelpbtn"
                >
                    VIEW MORE ON YELP
                </a>
            </div>
        </section>
    </>
);
export default YelpReviews;
